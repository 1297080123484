import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "key": "reusable",
  "langKey": "en",
  "title": "mcc-earsavers-1"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Executive Summary:`}</strong>{` 	`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`This page explains why we created and sent out free ear savers. We are not claiming these are certified for use and for legal reasons have to suggest that these be considered single use unless you feel comfortable using them longer (I have been using our 1st prototype daily for more than a month). `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`How our software can help your company bottom line while helping with communication, social distancing and keeping your customers and staff as safe as possible`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`What's so special about these (spoiler alert: They are made with plant material not petro-chemicals so they are bio-degradable and recyclable, but also UV resistant.)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`How you can get more Ear savers (spoiler alert: Free plans for your 3D printer, but now, we have also added an option where we can produce them for you.)`}</p>
      </li>
    </ul>
    <p>{`Hi! We were tired of our ears being uncomfortable from elastics on face masks and we were listening to some professionals wearing masks who complained that after a few minutes, and especially after hours of wearing elastic masks, their ears hurt from the elastics.`}</p>
    <p>{`We decided that this is where we could make a small, but practical difference.`}</p>
    <p>{`COVID-19 is with us for a while, round 1 may be over - or not, but we believe we are going to go through waves of it until a vaccine is widely available.`}</p>
    <p>{`With 'home sewn' masks, we saw that people were solving the problem in several ways by changing the design. But that doesn't help for the disposable ones that are widely in use by technicians, hospitals, wood and other machine workers.`}</p>
    <p>{`So we looked at designs that were available. They weren't as good as we thought we could do so after several tests we think we came up with a good design. We then shipped them out at no cost to our customers, one for every Maintenance Connection Everywhere WO technician license, because these are the people that use our software that most need to have a mask, whether they are working in hospitals, or airports, or submarines, or jails or fixing problems onsite for their customers.`}</p>
    <p>{`The main products we produce and sell around the world is our software, software that helps keep people in communication even when they aren't in the same room, software that helps keep machinery up and running with the Maintenance Connection family of EAM/CMMS software, software that helps you keep track of whether you are following your own cleaning guidelines with our 'Cleaning Solutions' including Key Performance Indicator (KPI) displays. `}</p>
    <p>{`We created this page for those that received these ear savers. But then, we have already had people ask if we can provide more, so we decided: Sure! `}<a parentName="p" {...{
        "href": "/how-to-make-your-own"
      }}>{`link here to ordering`}</a></p>
    <p><strong parentName="p">{`What makes these special:`}</strong>{` 	`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`First, the one we sent you and most of the ones we produce (we tell you otherwise) we use a plant based biodegradable plastic called PLA (Poly-Lactic Acid). I wouldn't recommend eating them; let me be clear: DO NOT eat, lick or suck them, but I'd be willing to bet they are safer than ingesting cleaning solutions `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`if you are reading this much after April 2020, please forgive the outdated political humor. It is a thermoplastic (means it can be shaped when heated) extruded from the starch of plants, such as sugar beets, maize (corn), cassava, sugarcane or cereals. Being derived from biological products, this type of plastic is biodegradable under the right conditions (such as an active compost heap, with enough oxygen and humidity) and therefore preferred for environmental impact over thermoplastics derived from fossil fuels.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`They are UV resistant. I'm told 10 to 15 years. We haven't tested that long yet - come back in a decade, and I'm sure it depends on how thick the ozone layer is where you live. But the point is, compared to many plastics, this plastic is known for being UV resistant.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You can clean them with so called 'hot' water (50C or below, the temperature your 'hot' water should be, if you have your hot water really hot, don't leave them long, they might melt - 140C for example.) Cleaning them in water does not mean that they are sterilized. See the discussion on sterilization.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`They are also safe to get wet. While they are biodegradable, they need the conditions, which includes heat, of a compost to decompose. We tested with them sitting in water for days with no discoloration or noticeable degredation.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`They are recyclable. #7 but you are better of composting them.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`They are orange! The color of the Maintenance Connection Canada checkmark maple leaf and the color of the Maintenance Connection Inc. checkmark cube.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`Downsides of this choice of plastic:`}</strong>{` 	`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Somewhere between 55C and 65C they will melt. Don't leave them on the dashboard of your car for example. And I didn't send any of these to our staff or customers in India, Costa Rica or the Philippines or other warm climate locations. If you are in one of those, sorry, we had to send you the high heat version - because your ambient temperature gets hot enough to melt, and there will be just too many places where it is even hotter than ambient where it would definitely melt.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`While they are recyclable, #7, most jurisdictions throw #7 into the junk pile. So make sure they are recyclable in your area before tossing them.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`They are more brittle than some plastics. This means if you bend them over against themselves, they will 'permanently'' bend or snap, and they will have 'those' white stretch marks. On them.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`While they are biodegradable, they don't biodegrade in a landfill - not enough water or heat - just like a carrot won't biodegrade in a landfill. Note this is VERY different from the normal petro-plastics like plastic bags that break down by UV into 'tiny unseen particles of plastic' that are still plastic and choke the life out of corals for example by starving them to death. These really compost down because they are made from plant material.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      